
















































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { api } from "@/store/modules/measureProcess";

import {
  MeasureProcessStepEfficacyCheck,
  NamedRef,
} from "@/store/modules/measureProcess/types";
import ADateChip from "@auditcloud/components/snippets/ADateChip.vue";

/*
Für ein zukünfiges Audit vormerken (DueDate ...)
Einem geplantem Audit zuweisen
Keine Wirksamkeitsprüfung
Wirksamkeitsprüfung sofort durchführen



*/

@Component({
  components: {
    ADateChip,
  },
})
export default class AMeasureProcessStepEfficacyCheck extends Vue {
  //missingDefault
  @Prop({
    type: MeasureProcessStepEfficacyCheck,
  })
  readonly doc!: MeasureProcessStepEfficacyCheck;

  @Getter(api.getters.getAuditList, {
    namespace: api.namespace,
  })
  auditRefList!: NamedRef[];

  @Action(api.actions.startStep, {
    namespace: api.namespace,
  })
  startStep!: (payload: any) => Promise<void>;
}
