var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AMeasureProcessStepContainer',{attrs:{"title":_vm.$ft(_vm.doc.title),"current":_vm.doc.state === 'wip' || _vm.doc.state === 'verify',"is-verifying":_vm.doc.state === 'verify',"attachments":_vm.doc.attachmentsCount,"comments":_vm.doc.commentsCount,"signature":_vm.doc.signature,"date":_vm.doc.dueDate,"user":_vm.doc.responsible}},[_c('div',{staticClass:"step-efficacy-check"},[(_vm.doc.state === 'new')?_c('div',{staticClass:"new"},[_c('v-radio-group',{staticClass:"mt-0",attrs:{"mandatory":false,"hide-details":""},model:{value:(_vm.radios),callback:function ($$v) {_vm.radios=$$v},expression:"radios"}},[_c('v-radio',{attrs:{"color":"primary","label":_vm.$t(
              'components.controls.measure_process_steps.measure_process_step_efficacy_check.reserve_future_label'
            ),"value":"radio-1"}}),_c('v-radio',{attrs:{"color":"primary","value":"radio-2"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',[_vm._v(" "+_vm._s(_vm.$t( "components.controls.measure_process_steps.measure_process_step_efficacy_check.assign_to_audit_label" ))+" ")])]},proxy:true}],null,false,3612725868)}),_c('v-select',{staticClass:"ml-8 mb-2",staticStyle:{"max-width":"16rem"},attrs:{"label":_vm.$t(
              'components.controls.measure_process_steps.measure_process_step_efficacy_check.select_audit_label'
            ),"outlined":"","dense":"","hide-details":""}}),_c('v-radio',{attrs:{"color":"primary","label":_vm.$t(
              'components.controls.measure_process_steps.measure_process_step_efficacy_check.no_performance_test_label'
            ),"value":"radio-3"}}),_c('v-radio',{attrs:{"color":"primary","label":_vm.$t(
              'components.controls.measure_process_steps.measure_process_step_efficacy_check.immediately_performance_test_label'
            ),"value":"radio-4"}})],1)],1):_c('div',{staticClass:"finished"},[_vm._v(" "+_vm._s(_vm.$t( "components.controls.measure_process_steps.measure_process_step_efficacy_check.date_for_performance_test" ))+" ")])]),(!['done', 'verify'].includes(_vm.doc.state))?_c('template',{slot:"actions"},[(_vm.doc.state === 'new')?_c('v-btn',{attrs:{"text":"","color":"success darken-1"},on:{"click":function($event){return _vm.startStep(_vm.doc.id)}}},[_vm._v(" Starten ")]):_vm._e(),(_vm.doc.state === 'wip')?_c('v-btn',{attrs:{"text":""}},[_vm._v("Abschließen")]):_vm._e(),(_vm.doc.state === 'new')?_c('v-btn',{attrs:{"text":""}},[_vm._v("Überspringen")]):_vm._e()],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }